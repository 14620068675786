<template lang="pug">
om-modal(
  :name="name"
  modalClasses="om-from-scratch-chooser-modal"
  :width="800"
  headerBorder
  :clickToClose="true"
)
  template(slot="modal-header")
    .row
      .col
        om-heading(h5) {{ $t('templateChooser.fromScratchModal.title') }}
    .brand-modal-action-icon.cursor-pointer(@click="$modal.hide(name)")
      close-icon(:width="12" :height="12" color="#AAB1C1")
  template(slot="modal-body")
    .row
      .col
        .from-scratch-chooser-container
          .from-scratch-chooser-item(@click="choose('empty')")
            img(:src="require('@/assets/admin/svg/templateChooser/empty.svg')")
            .from-scratch-chooser-item-title {{ $t('templateChooser.fromScratchModal.blank') }}
            .from-scratch-chooser-item-description {{ $t('templateChooser.fromScratchModal.fromElements') }}
          .from-scratch-chooser-item(@click="choose('flyer')")
            img(:src="require('@/assets/admin/svg/templateChooser/flyer.svg')")
            .from-scratch-chooser-item-title {{ $t('templateChooser.fromScratchModal.image') }}
            .from-scratch-chooser-item-description {{ $t('templateChooser.fromScratchModal.predesignedImage') }}
</template>
<script>
  export default {
    data: () => ({
      name: 'from-scratch-chooser',
    }),
    methods: {
      choose(type) {
        this.$modal.hide(this.name);
        this.$emit('start-from-scratch', type);
      },
    },
  };
</script>

<style lang="sass">
  .om-from-scratch-chooser-modal
    .brand-modal-header
      border: none !important
      .heading
        font-size: 24px !important
        text-align: center
    .from-scratch-chooser-container
      display: flex
      gap: 40px
      .from-scratch-chooser-item
        cursor: pointer
        width: 340px
        display: flex
        padding: 20px 0px 32px 0px
        flex-direction: column
        align-items: center
        gap: 8px
        flex: 1 0 0
        border-radius: 8px
        box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.04), 0px 4px 10px 0px rgba(0, 0, 0, 0.10)
        &-title
          font-size: 24px
          font-weight: 700
          line-height: 32px
          margin-top: 8px
          margin-bottom: 16px
        &-description
          font-size: 16px
          font-weight: 400
          line-height: 24px
</style>
